import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { FixedSizeList as List } from "react-window";
import styled from "styled-components";
import { CustomModal } from "components/CustomModal/CustomModal";
import { DataViewContainer } from "components/DataViewContainer/DataViewContainer";
import ExportCSVButton from "components/ExportCSVButton/ExportCSVButton";
import ClearIcon from "@material-ui/icons/Clear";
import {
  FormControl,
  InputLabel,
  Button,
  Grid,
  Typography,
  Input,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { EMAIL_LIST } from "utils/constants";

const nl2br = require("react-nl2br");

const CardStyles = styled.li`
  background: white;
  border: 1px solid #d6d4d4;
  padding: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

const HoldReason = (props) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState(0);
  const [search, setSearch] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [loading, setLoading] = useState(true);
  const { currentUser, path } = props;

  const handleClick = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleCopyText = (txt) => {
    navigator.clipboard.writeText(txt);
    return;
  };

  const handleEmailCompose = (acc) => {
    window.open(
      `mailto:${EMAIL_LIST}?subject=HOLD RESPONSE: Billing Hold - ${acc.AccountName}&body=Accession: ${acc.Accession}%0D%0AProvider: ${acc.Provider}`
    );
  };

  const handleClearSearchField = async () => {
    let searchField = document.getElementById("holds-search-field");
    searchField.value = "";
    let url;
    if (currentUser.role === "Admin") {
      url = `${process.env.REACT_APP_REST_API_HOST}rephold?apikey=${process.env.REACT_APP_REST_API_KEY}`;
    } else {
      url = `${process.env.REACT_APP_REST_API_HOST}rephold?user_email=${currentUser.email}&apikey=${process.env.REACT_APP_REST_API_KEY}`;
    }
    setLoading(true);
    let result = await axios.get(url);
    setLoading(false);
    setData(result.data);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    filterData(e.target.value);
  };

  const sortAgedCards = () => {
    let sortedAging;
    if (sortOrder === 1) {
      sortedAging = data.sort((a, b) => (a.Aging > b.Aging ? 1 : -1));
      setSortOrder(0);
    } else {
      sortedAging = data.sort((a, b) => (a.Aging < b.Aging ? 1 : -1));
      setSortOrder(1);
    }
    setData(sortedAging);
  };
  // exclude column list from filter
  const excludeColumns = ["Aging", "DOR", "Reason", "Status", "DOS"];

  // filter records by search text
  const filterData = async (value) => {
    const val = value.toUpperCase().trim();
    if (val === "") {
      let url;
      if (currentUser.role === "Admin") {
        url = `${process.env.REACT_APP_REST_API_HOST}rephold?apikey=${process.env.REACT_APP_REST_API_KEY}`;
      } else {
        url = `${process.env.REACT_APP_REST_API_HOST}rephold?user_email=${currentUser.email}&apikey=${process.env.REACT_APP_REST_API_KEY}`;
      }
      setLoading(true);
      let result = await axios.get(url);
      setLoading(false);
      setData(result.data);
    } else {
      const filteredData = data.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toUpperCase().includes(val)
        );
      });
      setData(filteredData);
    }
  };

  const fetchData = useCallback(async (user) => {
    let url;
    let result;
    if (user.role === "Admin") {
      url = `${process.env.REACT_APP_REST_API_HOST}rephold?apikey=${process.env.REACT_APP_REST_API_KEY}`;
      result = await axios.get(url);
      setLoading(false);
      setData(result.data);
    } else if (user.email && user.role === "Rep") {
      url = `${process.env.REACT_APP_REST_API_HOST}rephold?user_email=${user.email}&apikey=${process.env.REACT_APP_REST_API_KEY}`;
      result = await axios.get(url);
      setLoading(false);
      setData(result.data);
    } else {
      setLoading(false);
      setData([]);
    }
  }, []);

  useEffect(() => {
    fetchData(currentUser);
  }, [fetchData, currentUser]);

  return (
    <DataViewContainer
      pageTitle="Holds Data"
      loading={loading}
      fetchData={fetchData}
      currentUser={currentUser}
      setLoading={setLoading}
      path={path}
    >
      {!loading ? (
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <FormControl>
              <InputLabel htmlFor="holds-search-field">
                Search Records
              </InputLabel>
              <Input
                id="holds-search-field"
                onChange={handleSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClearSearchField}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <ExportCSVButton data={data} title="Export All Data" />
          </Grid>
          <Grid item xs={5} md={3}>
            <Typography variant="body1" style={{ marginTop: 10 }}>
              Total Holds: {data.length}
            </Typography>
            <Button
              onClick={sortAgedCards}
              variant="contained"
              color="primary"
              style={{ marginTop: 23, fontWeight: 700 }}
            >
              Sort Aged
            </Button>
          </Grid>
        </Grid>
      ) : undefined}
      <List
        style={{ marginTop: 20 }}
        className="list-container"
        innerElementType="ul"
        itemCount={data.length}
        itemSize={160}
        height={700}
        width={700}
      >
        {({ index, style }) => {
          return (
            <CardStyles style={style}>
              <div className="card-container">
                <div className="card-content">
                  {data[index].Accession ? (
                    <div
                      onClick={() => {
                        handleCopyText(data[index].Accession);
                      }}
                    >
                      <span className="card-info-title">Accession: </span>
                      {data[index].Accession}
                    </div>
                  ) : undefined}
                  {data[index].Patient ? (
                    <div
                      onClick={() => {
                        handleCopyText(data[index].Patient);
                      }}
                    >
                      <span className="card-info-title">Patient: </span>
                      {data[index].Patient}
                    </div>
                  ) : undefined}
                  {data[index].DOB ? (
                    <div
                      onClick={() => {
                        handleCopyText(data[index].DOB);
                      }}
                    >
                      <span className="card-info-title">Patient DOB: </span>
                      {data[index].DOB}
                    </div>
                  ) : undefined}
                  {data[index].DOS ? (
                    <div
                      onClick={() => {
                        handleCopyText(data[index].DOS);
                      }}
                    >
                      <span className="card-info-title">DOS: </span>
                      {data[index].DOS}
                    </div>
                  ) : undefined}
                  {data[index].TestType ? (
                    <div
                      onClick={() => {
                        handleCopyText(data[index].TestType);
                      }}
                    >
                      <span className="card-info-title">Test Type: </span>
                      {data[index].TestType}
                    </div>
                  ) : undefined}
                  {data[index].Status ? (
                    <div
                      onClick={() => {
                        handleCopyText(data[index].Status);
                      }}
                    >
                      <span className="card-info-title">Hold Type: </span>
                      {data[index].Status}
                    </div>
                  ) : undefined}
                </div>
                <div className="card-content">
                  {data[index].Aging ? (
                    <div
                      onClick={() => {
                        handleCopyText(data[index].Aging);
                      }}
                    >
                      <span className="card-info-title">Aging (Days): </span>
                      {data[index].Aging}
                    </div>
                  ) : undefined}
                  {data[index].AccountName ? (
                    <div
                      onClick={() => {
                        handleCopyText(data[index].AccountName);
                      }}
                    >
                      <span className="card-info-title">Account Name: </span>
                      {data[index].AccountName}
                    </div>
                  ) : undefined}
                  {data[index].Provider ? (
                    <div
                      onClick={() => {
                        handleCopyText(data[index].Provider);
                      }}
                    >
                      <span className="card-info-title">Provider: </span>
                      {data[index].Provider}
                    </div>
                  ) : undefined}
                  {data[index].SalesRep ? (
                    <div
                      onClick={() => {
                        handleCopyText(data[index].SalesRep);
                      }}
                    >
                      <span className="card-info-title">Sales Rep: </span>
                      {data[index].SalesRep}
                    </div>
                  ) : undefined}
                  {data[index].Supervisor ? (
                    <div
                      onClick={() => {
                        handleCopyText(data[index].Supervisor);
                      }}
                    >
                      <span className="card-info-title">Supervisor: </span>
                      {data[index].Supervisor}
                    </div>
                  ) : undefined}
                  <div className="open-card-info">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className="open-modal-btn"
                      style={{ fontWeight: 600, marginTop: 10 }}
                      onClick={() => {
                        handleClick(data[index]);
                      }}
                    >
                      More Info
                    </Button>
                  </div>
                </div>
              </div>
            </CardStyles>
          );
        }}
      </List>
      <CustomModal
        open={open}
        handleClose={handleClose}
        style={{ maxWidth: 500 }}
        className="hold-modal"
      >
        {selectedRow ? (
          <div>
            <span style={{ fontWeight: 600, fontSize: 16 }}>Hold Reason</span>
            <p>{nl2br(selectedRow.Reason)}</p>
            <br />
            <span style={{ fontWeight: 600, fontSize: 16 }}>Date Received</span>
            <p>{selectedRow.DOR}</p>
            <br />
            <span style={{ fontWeight: 600, fontSize: 16 }}>Primary Payer</span>
            <p>{selectedRow.PrimaryPayer}</p>
            <br />
            <span style={{ fontWeight: 600, fontSize: 16 }}>Type</span>
            <p>{selectedRow.Type}</p>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleEmailCompose(selectedRow)}
              >
                Contact
              </Button>
            </div>
          </div>
        ) : undefined}
      </CustomModal>
    </DataViewContainer>
  );
};

export default HoldReason;
