import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { CssBaseline, Container, Grid } from "@material-ui/core";
import { Topbar } from "./components";
import AuthHelpers from "utils/AuthHelpers";
import IdleModal from "components/IdleModal";
import IdleTimer from "react-idle-timer";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundImage: "url(/images/wallpaper/main.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100%",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    height: "100%",
  },
}));

const AdminLayout = (props) => {
  const [idleTimeout, setIdleTimeout] = useState(1000 * 60 * 9);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [showModal, setShowModal] = useState(false);

  let idleTimer = null;
  const { children, currentUser } = props;
  const classes = useStyles();

  const handleLogout = () => {
    AuthHelpers.signOut();
  };

  const onIdle = () => {
    if (isTimedOut) {
      handleLogout();
    } else {
      setShowModal(true);
      setIsTimedOut(true);
      setIdleTimeout(1000 * 60 * 1);
      idleTimer.reset();
    }
  };

  const onAction = () => {
    setIsTimedOut(false);
  };

  return (
    <div className={classes.root}>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onAction={onAction}
        onIdle={onIdle}
        debounce={250}
        timeout={idleTimeout}
      />
      <IdleModal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        handleLogout={handleLogout}
      />
      <CssBaseline />
      <Topbar handleLogout={handleLogout} currentUser={currentUser} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} justifyContent="center">
            {children}
          </Grid>
        </Container>
      </main>
    </div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default AdminLayout;
