import { toast } from "react-toastify";
import password from "secure-random-password";

// ! SUCCESS AND ERROR NOTIFICATIONS
export const notifyErr = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_CENTER,
  });
};

export const notifySuccess = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_CENTER,
  });
};

// ! DATE TRANSFORMATION
export const transformDate = (date) => {
  let month = date.slice(0, 2);
  let day = date.slice(2, 4);
  let year = date.slice(4);
  return year + "-" + month + "-" + day;
};

export const transformDateToField = (date) => {
  let dateArray = date.split("-");
  return dateArray[1] + dateArray[2] + dateArray[0];
};

export const updateSessionFormItems = (obj) => {
  let newFormItems = JSON.stringify(obj);
  sessionStorage.setItem("formItems", newFormItems);
  return;
};

export const generatePassword = () => {
  return password.randomPassword({
    length: 12,
    characters: [
      password.lower,
      password.upper,
      password.digits,
      { characters: password.symbols, exactly: 1 },
    ],
  });
};

// ! EXTRACT PAGE RULES
export const extractPageRules = (user) => {
  let pageRules = JSON.parse(user.labRole.pageRules);
  sessionStorage.setItem("pageRules", pageRules);
  return pageRules;
};
