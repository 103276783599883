import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import { makeStyles } from "@material-ui/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: {
    width: "100%",
    padding: "20px 12px",
    border: "1px solid #a1a1a1",
    borderRadius: 5,
  },
  errorMessage: {
    color: "red",
    marginLeft: theme.spacing(2),
  },
}));

const DateNumberFormat = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format="##/##/####"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
};

export const CustomPhoneField = (props) => {
  const classes = useStyles();

  const { fieldName, label } = props;
  return (
    <div className={classes.textField}>
      <Field name={fieldName}>
        {({ field }) => (
          <OutlinedInput type="text" fullWidth placeholder={label} {...field} />
        )}
      </Field>
    </div>
  );
};

export const CustomAccessCodeField = (props) => {
  const classes = useStyles();

  const { fieldName, label } = props;
  return (
    <div className={classes.textField}>
      <Field name={fieldName}>
        {({ field }) => (
          <TextField
            label={label}
            placeholder={label}
            variant="outlined"
            fullWidth
            autoComplete="off"
            {...field}
          />
        )}
      </Field>
      <ErrorMessage
        name={fieldName}
        component="span"
        className={classes.errorMessage}
      />
    </div>
  );
};

export const CustomDateField = (props) => {
  const classes = useStyles();

  const { fieldName, label } = props;
  return (
    <div className={classes.textField}>
      <Field name={fieldName}>
        {({ field }) => (
          <TextField
            label={label}
            placeholder={label}
            variant="outlined"
            fullWidth
            autoComplete="off"
            InputProps={{
              inputComponent: DateNumberFormat,
            }}
            {...field}
          />
        )}
      </Field>
      <ErrorMessage
        name={fieldName}
        component="span"
        className={classes.errorMessage}
      />
    </div>
  );
};

export const CustomEditDateField = (props) => {
  const classes = useStyles();
  const { fieldName, label } = props;
  return (
    <div className={classes.textField}>
      <Field name={fieldName}>
        {({ field }) => (
          <TextField
            label={label}
            placeholder={label}
            variant="outlined"
            fullWidth
            autoComplete="off"
            InputProps={{
              inputComponent: DateNumberFormat,
            }}
            {...field}
          />
        )}
      </Field>
      <ErrorMessage
        name={fieldName}
        component="span"
        className={classes.errorMessage}
      />
    </div>
  );
};

export const CustomInputField = (props) => {
  const classes = useStyles();

  const { fieldName } = props;
  return (
    <div className={classes.textField}>
      <Field name={fieldName}>
        {({ field }) => (
          <OutlinedInput
            id="outlined-adornment-npi"
            type="text"
            fullWidth
            placeholder="NPI Search"
            {...field}
          />
        )}
      </Field>
    </div>
  );
};

export const CustomPasswordField = (props) => {
  const classes = useStyles();
  const [showPass, setShowPass] = useState(false);
  const { fieldName, label, autocomplete } = props;

  const handleClickShowPassword = () => setShowPass(!showPass);
  const handleMouseDownPassword = (e) => e.preventDefault();

  return (
    <div className={classes.textField}>
      <Field name={fieldName}>
        {({ field }) => (
          <OutlinedInput
            type={showPass ? "text" : "password"}
            placeholder={label}
            variant="outlined"
            fullWidth
            autoComplete={autocomplete}
            {...field}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        )}
      </Field>
      <ErrorMessage
        name={fieldName}
        component="span"
        className={classes.errorMessage}
      />
    </div>
  );
};

export const CustomEditTextField = (props) => {
  const classes = useStyles();
  const [editText, setEditText] = useState(false);
  const { fieldName, label, autocomplete } = props;

  const handleClickEditText = () => setEditText(!editText);
  const handleMouseDownText = (e) => e.persist();

  return (
    <div className={classes.textField}>
      <Field name={fieldName}>
        {({ field }) => (
          <OutlinedInput
            type="text"
            placeholder={label}
            variant="outlined"
            fullWidth
            autoComplete={autocomplete}
            disabled={!editText}
            {...field}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickEditText}
                  onMouseDown={handleMouseDownText}
                  edge="end"
                >
                  {!editText ? <EditIcon /> : <CheckIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
        )}
      </Field>
      <ErrorMessage
        name={fieldName}
        component="span"
        className={classes.errorMessage}
      />
    </div>
  );
};

export const CustomTextField = (props) => {
  const classes = useStyles();
  const { fieldName, label, type, disabled, autocomplete, className } = props;
  return (
    <div className={classes.textField}>
      <Field name={fieldName}>
        {({ field }) => (
          <TextField
            autoComplete={autocomplete}
            type={type}
            label={label}
            variant="outlined"
            disabled={disabled}
            className={className}
            fullWidth
            {...field}
          />
        )}
      </Field>
      <ErrorMessage
        name={fieldName}
        component="span"
        className={classes.errorMessage}
      />
    </div>
  );
};

export const CustomSelectField = (props) => {
  const classes = useStyles();
  const { options, fieldName, label } = props;

  return (
    <div className={classes.textField}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="select-outlined-label">{label}</InputLabel>
        <Field name={fieldName}>
          {({ field }) => (
            <Select
              native
              fullWidth
              labelId="select-outlined-label"
              id="select-outlined"
              label={label}
              {...field}
            >
              <option aria-label="State" value="" />
              {options.map((opt) => (
                <option value={opt.value} key={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Select>
          )}
        </Field>
      </FormControl>
      <ErrorMessage
        name={fieldName}
        component="span"
        className={classes.errorMessage}
      />
    </div>
  );
};

export const CustomAutoCompleteField = (props) => {
  const classes = useStyles();
  const { items, fieldName, setFieldValue, label, displayValue, isProvider } =
    props;

  return (
    <div className={classes.textField}>
      <Autocomplete
        options={items}
        getOptionLabel={(option) => option[displayValue]}
        onChange={(e, newValue) => {
          if (isProvider) {
            setFieldValue(fieldName, newValue.value);
          } else {
            setFieldValue(fieldName, newValue[displayValue]);
          }
        }}
        onInputChange={(e, newValue) => {
          if (isProvider) {
            setFieldValue(fieldName, newValue.value);
          } else {
            setFieldValue(fieldName, newValue[displayValue]);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
      <ErrorMessage
        name={fieldName}
        component="span"
        className={classes.errorMessage}
      />
    </div>
  );
};

CustomInputField.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

CustomPasswordField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autocomplete: PropTypes.string,
};

CustomEditTextField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autocomplete: PropTypes.string,
};

CustomSelectField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
};

CustomTextField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string,
  className: PropTypes.string,
};

CustomAutoCompleteField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  label: PropTypes.string,
  isProvider: PropTypes.bool,
};
