import React, { useState } from "react";
import { DataViewContainer } from "components/DataViewContainer/DataViewContainer";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { notifyErr, notifySuccess } from "utils/helpers";
import axios from "axios";
import FormData from "form-data";
import FadeLoader from "react-spinners/FadeLoader";

const FileUploader = styled.div`
  border: 1px dashed black;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  height: 100%;

  button {
    margin-top: 15px;
    font-weight: 600;
  }
`;

const SpinnerContainer = styled.div`
  margin-top: 40px;
  height: 200px;
  text-align: center;
`;

const ImportCSV = ({ path }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const submitFile = async (file) => {
    const formData = new FormData();
    formData.append("fileUpload", file);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_REST_API_HOST}upload-csv?apikey=${process.env.REACT_APP_REST_API_KEY}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
        document.getElementById("file").value = "";
        setSelectedFile();
        notifySuccess("Your CSV has been successfully uploaded!");
      }
      return response;
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSubmission = async () => {
    if (!isFilePicked || !selectedFile) {
      notifyErr("Please select a file to upload!");
      return;
    }
    if (!selectedFile.name.includes(".csv")) {
      notifyErr("Must be a CSV file!");
      setSelectedFile();
      return;
    }
    setLoading(true);
    await submitFile(selectedFile);
    return;
  };

  // useEffect(() => {
  //   if (loading) return "Loading";
  // }, [loading]);

  return (
    <DataViewContainer pageTitle="Upload CSV Data" path={path}>
      <FileUploader>
        <input type="file" name="file" id="file" onChange={changeHandler} />
        <div>
          <Button
            onClick={handleSubmission}
            variant="contained"
            color="primary"
          >
            UPLOAD FILE
          </Button>
        </div>
      </FileUploader>
      {loading ? (
        <SpinnerContainer>
          <FadeLoader />
        </SpinnerContainer>
      ) : undefined}
    </DataViewContainer>
  );
};

export default ImportCSV;
