import React, { useState, useMemo, useEffect, useCallback } from "react";
// import PropTypes from "prop-types";
import axios from "axios";
import { DataTable } from "components/DataTable/DataTable";
import { DataViewContainer } from "components/DataViewContainer/DataViewContainer";
import SelectColumnFilter from "components/DataTable/SelectColumnFilter";
import DateFilter from "components/DataTable/DateFilter";
import moment from "moment";

const SampleCounts = (props) => {
  const [data, setData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [enableCSV, setEnableCSV] = useState(0);
  const [loading, setLoading] = useState(true);
  const { currentUser, path } = props;
  const currentYear = moment().format("YYYY");
  const currentMonth = moment().format("M");

  const adminColumns = useMemo(() => [
    {
      Header: "Account Name",
      accessor: "AccountName",
    },
    {
      Header: "DOS",
      accessor: "DOS",
      Filter: DateFilter,
      filter: "dateBetween",
    },
    {
      Header: "Type",
      accessor: "Type",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Sales Rep",
      accessor: "SalesRep",
    },
    {
      Header: "Supervising Rep",
      accessor: "SuperRep",
    },
    {
      Header: "Count",
      accessor: "Count",
    },
  ]);

  const repColumns = useMemo(() => [
    {
      Header: "Account Name",
      accessor: "AccountName",
    },
    {
      Header: "Rep",
      accessor: "Rep",
    },
    {
      Header: "DOS",
      accessor: "DOS",
      Filter: DateFilter,
      filter: "dateBetween",
    },
    {
      Header: "Type",
      accessor: "TYPE",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Count",
      accessor: "Count",
    },
  ]);

  // Function to grab different columns for rep and admin views
  const getColumnType = (user) => {
    if (user.role === "Admin") {
      return adminColumns;
    } else if (user.email && user.role === "Rep") {
      return repColumns;
    } else {
      return null;
    }
  };

  // Function to query data based off of rep and admin roles
  const fetchData = useCallback(async (user, cy, cm) => {
    let url;
    let result;
    if (user.role === "Admin") {
      url = `${process.env.REACT_APP_REST_API_HOST}adminsamplecountmonthly?year=${cy}&month=${cm}&apikey=${process.env.REACT_APP_REST_API_KEY}`;
      result = await axios.get(url);
      setLoading(false);
      setData(result.data);
    } else if (user.email && user.role === "Rep") {
      url = `${process.env.REACT_APP_REST_API_HOST}salessmpstmonthly?user_email=${user.email}&year=${cy}&month=${cm}&apikey=${process.env.REACT_APP_REST_API_KEY}`;
      result = await axios.get(url);
      setLoading(false);
      setData(result.data);
    } else {
      setLoading(false);
      setData([]);
    }
  }, []);

  // Function to query data based off of rep and admin roles
  const fetchYearData = useCallback(async (user, cy) => {
    let url;
    let result;
    if (user.email && user.role === "Rep") {
      setEnableCSV(1);
      url = `${process.env.REACT_APP_REST_API_HOST}salessmpstmonthly?user_email=${user.email}&year=${cy}&apikey=${process.env.REACT_APP_REST_API_KEY}`;
      result = await axios.get(url);
      setEnableCSV(2);
      setYearData(result.data);
    } else if (user.role === "Admin") {
      setEnableCSV(1);
      url = `${process.env.REACT_APP_REST_API_HOST}adminsamplecountmonthly?year=${cy}&apikey=${process.env.REACT_APP_REST_API_KEY}`;
      result = await axios.get(url);
      setEnableCSV(2);
      setYearData(result.data);
    } else {
      setEnableCSV(2);
      setYearData([]);
    }
  }, []);

  // Fetch data
  useEffect(() => {
    fetchData(currentUser, currentYear, currentMonth);
  }, [fetchData, currentUser, currentMonth, currentYear]);

  return (
    <DataViewContainer
      pageTitle="Sample Counts Data"
      loading={loading}
      fetchData={fetchData}
      currentUser={currentUser}
      setLoading={setLoading}
    >
      {!loading ? (
        <>
          <DataTable
            data={data}
            columns={getColumnType(currentUser)}
            yearData={yearData}
            enableCSV={enableCSV}
            fetchYearData={fetchYearData}
            currentUser={currentUser}
          />
        </>
      ) : undefined}
    </DataViewContainer>
  );
};

export default SampleCounts;
