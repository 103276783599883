import React from "react";
import { Container, Grid, Paper, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SignInForm from "./components";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(204, 204, 204, 0.17)",
  },
  container: {
    maxWidth: 800,
    minWidth: 300,
    height: "100vh",
  },
  gridContainer: {
    height: "100%",
  },
  paper: {
    width: "100%",
    minHeight: 300,
    padding: 20,
    textAlign: "center",
  },
}));

const SignIn = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid
          container
          className={classes.gridContainer}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="elevation" elevation={3}>
              <Typography variant="h4" style={{ color: "red" }}>
                If this is your first time logging into the new sales portal,
                please reset your password by{" "}
                <Link href="/forgot-pass">clicking here</Link>.
              </Typography>
              <SignInForm history={props.history} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default SignIn;
