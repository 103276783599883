import React from "react";
import { CSVLink } from "react-csv";
import { Button } from "@material-ui/core";

const ExportCSVButton = ({ data, title }) => {
  return (
    <div style={{ marginTop: 5 }}>
      <Button variant="contained" color="primary">
        <CSVLink
          filename="sales-portal-data.csv"
          data={data}
          style={{ color: "white", fontWeight: 600 }}
        >
          {title}
        </CSVLink>
      </Button>
    </div>
  );
};

export default ExportCSVButton;
