import React from "react";
import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";
import LoadingData from "components/LoadingData/LoadingData";
import MonthPicker from "components/MonthPicker/MonthPicker";

const TableStyles = styled.div`
  padding: 1rem;

  table {
    background-color: white;
  }
`;

const TitleStyles = styled.div`
  padding: 1rem;
  margin-left: 20px;
  line-height: 1;
  h2 {
    color: black;
    font-size: 30px;
    font-weight: 600;
  }
`;

const ContainerStyles = styled.div`
  max-width: 1400px;
  @media screen and (max-width: 1650px) {
    max-width: 1200px;
  }
  @media screen and (max-width: 1400px) {
    max-width: 900px;
  }
  @media screen and (max-width: 900px) {
    max-width: 400px;
  }
`;

export const DataViewContainer = ({
  children,
  pageTitle,
  button,
  clickFunction,
  loading,
  fetchData,
  currentUser,
  setLoading,
  path,
}) => {
  return (
    <Grid>
      <ContainerStyles>
        <TitleStyles>
          <h2 style={{ textAlign: "center" }}>{pageTitle}</h2>
          {path !== "/rep-review" &&
          path !== "/user-management" &&
          path !== "/rep-hold" &&
          path !== "/upload-csv" ? (
            <>
              <span>Choose Received Month</span>
              <MonthPicker
                fetchData={fetchData}
                setLoading={setLoading}
                currentUser={currentUser}
              />
            </>
          ) : undefined}
          {loading ? <LoadingData /> : undefined}
        </TitleStyles>
        <TableStyles>
          {button ? (
            <Button
              variant="contained"
              color="primary"
              onClick={clickFunction}
              style={{ marginBottom: 20, fontWeight: 600 }}
            >
              {button}
            </Button>
          ) : undefined}
          {children}
        </TableStyles>
      </ContainerStyles>
    </Grid>
  );
};
