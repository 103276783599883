import React, { useEffect } from "react";
import { Container, CssBaseline, Avatar, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Copyright } from "components/PageComponents";
import { FaUsers as UsersIcon } from "react-icons/fa";
import { VERIFY_USER_TOKEN } from "apollo/queries/userQueries";
import { RESET_PASSWORD } from "apollo/mutations/userActions";
import { Redirect } from "react-router-dom";
import ChangePasswordForm from "components/ChangePasswordForm";
import { useMutation, useLazyQuery } from "@apollo/client";
import { notifyErr, notifySuccess } from "utils/helpers";
import Spinner from "react-spinkit";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    border: "1px solid #cacaca",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#1d5d90",
  },
  container: {
    paddingTop: 100,
  },
}));

const ResetPassword = (props) => {
  const classes = useStyles();
  const urlParams = new URLSearchParams(window.location.search);
  const { history } = props;

  const [verifyToken, { data, loading, error }] =
    useLazyQuery(VERIFY_USER_TOKEN);

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {
      notifySuccess("Password has been successfully changed!");
      history.push("/");
    },
    onError: (error) => {
      notifyErr(error.message);
      console.log(error.message);
    },
  });

  useEffect(() => {
    verifyToken({
      variables: {
        token: urlParams.get("token"),
      },
    });
  }, []);

  if (loading) return <Spinner name="line-scale-pulse-out-rapid" />;
  if (error) return <Redirect to="/" />;

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <UsersIcon />
        </Avatar>
        <ChangePasswordForm
          mutationFunc={resetPassword}
          userInfo={data && data.verifyUserToken.email}
        />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ResetPassword;
