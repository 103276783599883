import React from "react";
import { Container, Typography } from "@material-ui/core";
import HashLoader from "react-spinners/HashLoader";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  margin-top: 40px;
`;

const LoadingData = () => {
  return (
    <Container
      style={{
        marginTop: 100,
        height: 150,
      }}
    >
      <Typography align="center">Your data is loading...</Typography>
      <HashLoader size={100} css={override} color="#171e9e" />
    </Container>
  );
};

export default LoadingData;
