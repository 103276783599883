import React, { useEffect } from "react";
import { Container, CssBaseline, Avatar, Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FaUsers as UsersIcon } from "react-icons/fa";
import { VERIFY_USER_TOKEN } from "apollo/queries/userQueries";
import { useLazyQuery } from "@apollo/client";
import { Copyright } from "components/PageComponents";
import NewLabUserForm from "./components";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    border: "1px solid #cacaca",
    marginTop: theme.spacing(8),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#1d5d90",
  },
}));

const CreateUser = (props) => {
  const classes = useStyles();
  const { history } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const [verifyToken, { data, loading, error }] =
    useLazyQuery(VERIFY_USER_TOKEN);

  useEffect(() => {
    verifyToken({
      variables: {
        token: urlParams.get("token"),
      },
    });
  }, []);

  if (loading) return <p>Verifying Token...</p>;
  if (error) return <Redirect to="/" />;

  return (
    <Container maxWidth="sm">
      <Grid container justifyContent="center" direction="column">
        <CssBaseline />
        <Grid item xs={12}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <UsersIcon />
            </Avatar>
            {data ? (
              <NewLabUserForm
                email={data.verifyUserToken.email}
                history={history}
                role={data.verifyUserToken.role}
              />
            ) : undefined}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

CreateUser.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CreateUser;
