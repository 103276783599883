// TOP LEVEL IMPORTS
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { logout } from "utils/helpers";

// EXPORTED COMPONENT
// ==============================
const PublicRoute = (props) => {
  const {
    currentUser,
    redirectOnAuth,
    showHeader,
    showFooter,
    component: Component,
    path,
    backgroundColor,
    layout: Layout,
    ...rest
  } = props;

  if (currentUser && currentUser.id && currentUser.role) {
    return <Redirect to="/master-claim-detail" />;
  }

  return (
    <Route
      {...rest}
      path={path}
      render={(args) => {
        return (
          <Layout
            currentUser={currentUser}
            backgroundColor={backgroundColor}
            showHeader={showHeader}
            showFooter={showFooter}
          >
            <Component currentUser={currentUser} {...args} />
          </Layout>
        );
      }}
    />
  );
};

// PROPS
// ==============================
PublicRoute.defaultProps = {
  redirectOnAuth: true,
};

// EXPORT
// ==============================
export default PublicRoute;
