import React from "react";
import { Button, Grid, Typography, Link } from "@material-ui/core";
import { Formik, Form } from "formik";
import { makeStyles } from "@material-ui/styles";
import { CustomPasswordField } from "components/CustomFormFields";
import { ResetPasswordSchema } from "utils/formSchema";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

const ChangePasswordForm = (props) => {
  const classes = useStyles();
  const { userInfo, mutationFunc, userManage } = props;

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPass: "",
      }}
      validationSchema={ResetPasswordSchema}
      onSubmit={(values, { setSubmitting }) => {
        mutationFunc({
          variables: {
            email: userInfo,
            password: values.password,
          },
        });
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className={classes.form}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <CustomPasswordField
                fieldName="password"
                label="Password"
                autocomplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomPasswordField
                fieldName="confirmPass"
                label="Confirm Password"
                autocomplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                size="medium"
                color="primary"
                fullWidth
              >
                Reset Password
              </Button>
            </Grid>
            {!userManage ? (
              <Grid item xs={12} md={12} align="center">
                <Typography variant="body1">
                  <Link href="/">Go To Login</Link>
                </Typography>
              </Grid>
            ) : undefined}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

ChangePasswordForm.propTypes = {
  mutationFunc: PropTypes.func.isRequired,
  userInfo: PropTypes.string,
};

export default ChangePasswordForm;
