import * as Yup from "yup";
import { PASS_REGEX } from "utils/constants";

export const CreateNewUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be valid email address")
    .required("Required!"),
  password: Yup.string()
    .min(6, "Must be at least 6 characters")
    .matches(
      PASS_REGEX,
      "Password must contain at least one number or special character and one capital letter"
    )
    .required("Required!"),
  confirmPass: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required!"),
});

export const EditUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be valid email address")
    .required("Required!"),
  password: Yup.string()
    .min(6, "Must be at least 6 characters")
    .matches(
      PASS_REGEX,
      "Password must contain at least one number or special character and one capital letter"
    ),
  confirmPass: Yup.string()
    .when("password", {
      is: (value) => value,
      then: Yup.string().required("Confirm password is required"),
    })
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const CreatePortalUserSchema = Yup.object().shape({
  username: Yup.string()
    .matches(/^\S*$/, "No spaces allowed in the username")
    .required("Required!"),
  email: Yup.string()
    .email("Must be valid email address")
    .required("Required!"),
  password: Yup.string()
    .min(6, "Must be at least 6 characters")
    .matches(
      PASS_REGEX,
      "Password must contain at least one number or special character and one capital letter"
    )
    .required("Required!"),
  confirmPass: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required!"),
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be valid email address")
    .required("Required!"),
  password: Yup.string()
    .min(6, "Must be at least 6 characters")
    .required("Required!"),
});

export const PatientRegisterSchema = Yup.object().shape({
  firstName: Yup.string().required("Required!"),
  lastName: Yup.string().required("Required!"),
  dob: Yup.string().required("Required!"),
  email: Yup.string()
    .email("Must be valid email address")
    .required("Required!"),
  password: Yup.string()
    .min(6, "Must be at least 6 characters")
    .matches(
      PASS_REGEX,
      "Password must contain at least one number or special character and one capital letter"
    )
    .required("Required!"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required!"),
});

export const PatientUpdateSchema = Yup.object().shape({
  firstName: Yup.string().required("Required!"),
  lastName: Yup.string().required("Required!"),
});

export const RegisterTestSchema = Yup.object().shape({
  accessCode: Yup.string()
    .min(9, "Access Code must be 9 digits")
    .required("Required!"),
  dob: Yup.string()
    .min(8, "Date of Birth must be 8 digits")
    .required("Required!"),
});

export const RegisterTestDemoSchema = Yup.object().shape({
  dob: Yup.string()
    .min(8, "Date of Birth must be 8 digits")
    .required("Required!"),
  firstName: Yup.string().required("Required!"),
  lastName: Yup.string().required("Required!"),
});

export const RegisterTestSchemaNoDate = Yup.object().shape({
  accessCode: Yup.string()
    .min(9, "Access Code must be 9 digits")
    .required("Required!"),
});

export const RequiredEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be valid email address")
    .required("Required!"),
});

export const NewUserInviteSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be valid email address")
    .required("Required!"),
  role: Yup.string().required("Required!"),
});

const passRegex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,65}$/;
export const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Must be at least 6 characters")
    .matches(
      passRegex,
      "Password must contain at least one number, one special character, and one capital letter"
    )
    .required("Required!"),
  confirmPass: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required!"),
});

export const AccountSchema = Yup.object().shape({
  accountName: Yup.string().required("Required!"),
  webDest: Yup.string().required("Required!"),
});

export const ProviderSchema = Yup.object().shape({
  firstName: Yup.string().required("Required!"),
  lastName: Yup.string().required("Required!"),
  npi: Yup.string()
    .matches(/^[0-9]{10}$/, {
      message: "NPI Must be 10 numbers",
    })
    .required("Required!"),
});

export const PatientOrderTestInfoSchema = Yup.object().shape({
  // Patient Information
  firstName: Yup.string().required("Required!"),
  lastName: Yup.string().required("Required!"),
  email: Yup.string()
    .email("Must be valid email address")
    .required("Required!"),
  gender: Yup.string().required("Required!"),
  race: Yup.string().required("Required!"),
  // Patient Shipping Information
  street1: Yup.string().required("Required!"),
  city: Yup.string().required("Required!"),
  zip: Yup.string().required("Required!"),
  state: Yup.string().required("Required!"),
});
