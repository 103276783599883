import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
} from "@apollo/client";
import logger from "apollo-link-logger";
import authLink from "./authLink";
import { resolvers } from "./resolvers";

// for some reason, need to pass uri in via links like https://github.com/apollographql/apollo-link/issues/1246#issuecomment-656092271
let links = [
  authLink,
  logger,
  new HttpLink({
    uri: process.env.REACT_APP_API_HOST,
    credentials: "include",
  }),
];

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from(links),
  resolvers,
  fetchOptions: {
    mode: "no-cors",
  },
});
