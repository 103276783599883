import React, { useMemo } from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useBlockLayout,
} from "react-table";
import { matchSorter } from "match-sorter";
import ExportCSVButton from "components/ExportCSVButton/ExportCSVButton";
import styled from "styled-components";
import { FixedSizeList } from "react-window";
import scrollbarWidth from "./scrollbarWidth";
import YearPicker from "components/YearPicker/YearPicker";

// const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
//   const [value, setValue] = useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <label htmlFor="search">
//       Search:
//       <input
//         value={value || ""}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         placeholder={`Search`}
//       />
//     </label>
//   );
// };

const Styles = styled.div`
  padding: 1rem;

  .table {
    display: inline-block;
    border-spacing: 0;
    border: 1px solid black;
    overflow: scroll;
    background-color: white;

    > :not(:first-child) {
      border-top: none;
    }

    .table-header {
      width: 100%;
    }

    [role="row"] {
      border-bottom: 1px solid black;
    }

      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      width: 400px;
      overflow: hidden;
      overflow-wrap: break-word;
      font-size: 10px;
      font-weight: 600;

      :last-child {
        border-right: 1px solid black;
      }
    }
  }
`;

function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;

  if (ed || sd) {
    return rows.filter((r) => {
      const cellDate = new Date(r.values[id]);

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else if (ed) {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
}

export const DataTable = ({
  columns,
  data,
  yearData,
  enableCSV,
  fetchYearData,
  currentUser,
  path,
}) => {
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      dateBetween: dateBetweenFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    totalColumnsWidth,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useBlockLayout
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="tr"
        >
          {row.cells.map((cell) => {
            return (
              <div {...cell.getCellProps()} className="td">
                {cell.render("Cell")}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows]
  );

  return (
    <Styles>
      <div {...getTableProps()} className="table">
        <div className="table-header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <div className="table-head-column" {...column.getHeaderProps()}>
                  <div className="table-head-title">
                    {column.canGroupBy ? (
                      // If the column can be grouped, let's add a toggle
                      <span {...column.getGroupByToggleProps()}>
                        {column.isGrouped ? "🛑 " : "👊 "}
                      </span>
                    ) : null}
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </div>
                  {/* Render the columns filter UI */}
                  <div className="table-head-filter">
                    {column.canFilter ? column.render("Filter") : null}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()}>
          <FixedSizeList
            height={400}
            itemCount={rows.length}
            itemSize={60}
            width={totalColumnsWidth + scrollBarSize}
          >
            {RenderRow}
          </FixedSizeList>
        </div>
      </div>
      <div
        colSpan={visibleColumns.length}
        style={{
          textAlign: "left",
          display: "flex",
        }}
      >
        <ExportCSVButton
          data={rows.map((item) => item.original)}
          title={
            path !== "/user-management"
              ? "Export Selected Month CSV"
              : "Export All"
          }
        />
        {path !== "/rep-review" && path !== "/user-management" ? (
          <YearPicker
            yearData={yearData}
            enableCSV={enableCSV}
            fetchYearData={fetchYearData}
            currentUser={currentUser}
          />
        ) : undefined}
      </div>
    </Styles>
  );
};
