import React from "react";
import { Formik, Form } from "formik";
import { Grid, Button, Typography } from "@material-ui/core";
import {
  CustomTextField,
  CustomSelectField,
} from "components/CustomFormFields";
import { makeStyles } from "@material-ui/styles";
import { NewUserInviteSchema } from "utils/formSchema";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 400,
  },
}));

const ROLES = [
  {
    value: "Rep",
    label: "Rep",
  },
  {
    value: "Admin",
    label: "Admin",
  },
];

const InviteEmailModal = (props) => {
  const classes = useStyles();
  const { inviteUser } = props;

  return (
    <div>
      <Typography variant="h3" gutterBottom align="center">
        Invite New User
      </Typography>
      <Formik
        initialValues={{
          email: "",
          role: "Rep",
        }}
        validationSchema={NewUserInviteSchema}
        onSubmit={(values, { setSubmitting }) => {
          inviteUser({
            variables: {
              email: values.email,
              role: values.role,
            },
          });
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className={classes.form}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <CustomTextField fieldName="email" label="Email" type="email" />
              </Grid>
              <Grid item xs={12}>
                <CustomSelectField
                  options={ROLES}
                  fieldName="role"
                  label="Role *"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={classes.button}
                  fullWidth
                >
                  Invite New User
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

InviteEmailModal.propTypes = {
  handleClose: PropTypes.func,
  inviteUser: PropTypes.func.isRequired,
};

export default InviteEmailModal;
