import React from "react";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { Grid, Button, Typography } from "@material-ui/core";
import { notifyErr, notifySuccess } from "utils/helpers";
import {
  CustomTextField,
  CustomPasswordField,
} from "components/CustomFormFields";
import PropTypes from "prop-types";
import { CreateNewUserSchema } from "utils/formSchema";
import { CREATE_NEW_USER } from "apollo/mutations/userActions";

const NewUserForm = (props) => {
  const { email, history, role } = props;

  const [registerUser] = useMutation(CREATE_NEW_USER, {
    onCompleted: () => {
      notifySuccess("New account created, proceed to login.");
      history.push("/");
    },
    onError: (err) => {
      console.error(err);
      notifyErr(err.graphQLErrors[0].message);
    },
  });

  return (
    <div>
      <Typography variant="h3" align="center" gutterBottom>
        Register New User
      </Typography>
      <Formik
        initialValues={{
          email,
          role,
          firstName: "",
          lastName: "",
          password: "",
          confirmPass: "",
        }}
        validationSchema={CreateNewUserSchema}
        onSubmit={(values, { setSubmitting }) => {
          registerUser({
            variables: {
              ...values,
            },
          });
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <CustomTextField
                  fieldName="email"
                  label="Email"
                  type="email"
                  disabled={true}
                  autocomplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fieldName="role"
                  label="Role"
                  type="text"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fieldName="firstName"
                  label="First Name"
                  type="text"
                  autocomplete="first-name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fieldName="lastName"
                  label="Last Name"
                  type="text"
                  autocomplete="last-name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomPasswordField
                  fieldName="password"
                  label="Password"
                  autocomplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomPasswordField
                  fieldName="confirmPass"
                  autocomplete="new-password"
                  label="Confirm Password"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  size="medium"
                  color="primary"
                  fullWidth
                >
                  Create New User
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

NewUserForm.propTypes = {
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export default NewUserForm;
