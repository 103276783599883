import React from "react";
import { Button, Grid } from "@material-ui/core";
import { Formik, Form } from "formik";
import { CustomTextField } from "components/CustomFormFields";
import { makeStyles } from "@material-ui/styles";
import { RequiredEmailSchema } from "utils/formSchema";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  datePicker: {
    width: "100%",
    textAlign: "center",
    height: "56px",
    fontSize: "18px",
    borderRadius: "4px",
    marginBottom: 10,
    marginTop: 10,
    borderStyle: "none",
  },
  paper: {
    padding: 10,
    textAlign: "center",
    background: "#ffc9c9",
    marginBottom: 10,
  },
  paperSuccess: {
    background: "#adf9b4",
    padding: 10,
    textAlign: "center",
    marginBottom: 10,
  },
}));

const EmailPasswordForm = (props) => {
  const classes = useStyles();
  const { mutationFunc } = props;

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={RequiredEmailSchema}
      onSubmit={(values, { setSubmitting }) => {
        mutationFunc({
          variables: {
            ...values,
          },
        });
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, setFieldValue, resetForm }) => (
        <Form className={classes.form}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <CustomTextField
                fieldName="email"
                label="Email"
                type="email"
                autocomplete="current-email"
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Button
                type="submit"
                disabled={isSubmitting}
                className={classes.finishButton}
                variant="contained"
                fullWidth
                size="medium"
                color="primary"
              >
                Recover Password
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

EmailPasswordForm.propTypes = {
  mutationFunc: PropTypes.func,
};

export default EmailPasswordForm;
