import { client } from "apollo/client";
import { LOGIN, LOGOUT } from "apollo/mutations/userActions";
import { notifySuccess } from "utils/helpers";
import { history } from "../history";

const AuthHelpers = {};

AuthHelpers.handleLogin = ({ email, password }) =>
  new Promise(async (resolve, reject) => {
    try {
      // sign in with email
      const { data } = await client.mutate({
        mutation: LOGIN,
        variables: {
          password,
          email,
        },
      });
      sessionStorage.setItem("token", data.login.token);
      history.go("master-claim-detail");
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });

AuthHelpers.signOut = () =>
  new Promise(async (resolve, reject) => {
    try {
      // sign in with email
      await client.mutate({
        mutation: LOGOUT,
      });

      // reset the store
      await client.resetStore();
      sessionStorage.removeItem("token");
      // let user know
      notifySuccess("You've successfully logged out...");
      history.go("/");
      resolve(true);
    } catch (err) {
      reject(err);
    }
  });

export default AuthHelpers;
