/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import {
  List,
  ListItem,
  Divider,
  ListSubheader,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { pages, adminPages } from "./listItems";
import PropTypes from "prop-types";

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SideBarItems = (props) => {
  const { user, ...rest } = props;

  return (
    <>
      <List {...rest}>
        <ListSubheader inset>Menu</ListSubheader>
        {pages.map((page) => (
          <ListItem
            button
            key={page.title}
            component={CustomRouterLink}
            to={page.href}
          >
            <ListItemIcon>{page.icon}</ListItemIcon>
            <ListItemText primary={page.title} />
          </ListItem>
        ))}
        {user.role === "Admin" ? (
          <>
            <Divider />
            <ListSubheader inset>Admin Menu</ListSubheader>
            {adminPages.map((page) => (
              <ListItem
                button
                key={page.title}
                component={CustomRouterLink}
                to={page.href}
              >
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText primary={page.title} />
              </ListItem>
            ))}
          </>
        ) : undefined}
      </List>
      <Divider />
    </>
  );
};

SideBarItems.propTypes = {
  user: PropTypes.object.isRequired,
};

export default SideBarItems;
