import React from "react";
import {
  Container,
  Typography,
  CssBaseline,
  Avatar,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Copyright } from "components/PageComponents";
import { FaKey as KeyIcon } from "react-icons/fa";
import { useMutation } from "@apollo/client";
import { SEND_RESET_PASS_LINK } from "apollo/mutations/userActions";
import { Link } from "react-router-dom";
import { notifyErr, notifySuccess } from "utils/helpers";
import EmailPasswordForm from "components/EmailPasswordForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    border: "1px solid #cacaca",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#1d5d90",
  },
  container: {
    paddingTop: theme.spacing(8),
  },
  loginLinkContainer: {
    marginTop: 10,
  },
}));

const ForgotPassword = ({ history }) => {
  const classes = useStyles();

  const [recoverPassEmail] = useMutation(SEND_RESET_PASS_LINK, {
    onCompleted: (data) => {
      if (data) {
        notifySuccess(`Password reset email sent successfully!`);
        history.push("/");
      }
    },
    onError: (err) => {
      console.error(err);
      notifyErr(err.graphQLErrors[0].message);
    },
  });

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <KeyIcon />
        </Avatar>
        <Typography variant="h3">Recover Password</Typography>
        <EmailPasswordForm mutationFunc={recoverPassEmail} />
        <div className={classes.loginLinkContainer}>
          <Link to="/">Return to Login</Link>
        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default ForgotPassword;
