import React from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import {
  FcBullish,
  FcBusinessman,
  FcUpload,
  FcMindMap,
  FcBiomass,
  FcCollaboration,
  FcOrganization,
  FcSettings,
} from "react-icons/fc";

let pages = [
  {
    title: "Master Claim Detail",
    href: "/master-claim-detail",
    icon: <FcBullish style={{ fontSize: 20 }} />,
  },
  {
    title: "Order Detail",
    href: "/order-detail",
    icon: <FcBiomass style={{ fontSize: 20 }} />,
  },
  {
    title: "Sample Counts",
    href: "/sample-counts",
    icon: <FcMindMap style={{ fontSize: 20 }} />,
  },
  {
    title: "Rep Holds",
    href: "/rep-hold",
    icon: <FcOrganization style={{ fontSize: 20 }} />,
  },
  {
    title: "User Settings",
    href: "/user-settings",
    icon: <FcSettings style={{ fontSize: 20 }} />,
  },
];

let adminPages = [
  {
    title: "Rep Review",
    href: "/rep-review",
    icon: <FcBusinessman style={{ fontSize: 20 }} />,
  },
  {
    title: "Upload CSV",
    href: "/upload-csv",
    icon: <FcUpload style={{ fontSize: 20 }} />,
  },
  {
    title: "User Management",
    href: "/user-management",
    icon: <FcCollaboration style={{ fontSize: 20 }} />,
  },
];

export { pages, adminPages };
