import React from "react";
import AppRoutes from "./Routes";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@material-ui/styles";
import { GlobalProvider } from "./context/GlobalState";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme from "./theme";
import { client } from "apollo/client.js";
import moment from "moment-timezone";
import "./assets/scss/index.scss";
import { SETTINGS } from "utils/constants";

moment.tz.setDefault(SETTINGS.timezone);

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalProvider>
            <AppRoutes />
          </GlobalProvider>
        </>
      </ThemeProvider>
      <ToastContainer style={{ textAlign: "center" }} />
    </ApolloProvider>
  );
}

export default App;
