import React from "react";
import {
  CustomPasswordField,
  CustomTextField,
  CustomSelectField,
} from "components/CustomFormFields";
import { Formik, Form } from "formik";
import { Grid, Typography, Button } from "@material-ui/core";
import { EditUserSchema } from "utils/formSchema";
import { CLOSING } from "ws";

const ROLES = [
  {
    value: "Rep",
    label: "Rep",
  },
  {
    value: "Admin",
    label: "Admin",
  },
];

const EditUserForm = (props) => {
  const { selectedUser, mutationFunc } = props;

  return (
    <>
      <Formik
        initialValues={{
          email: selectedUser.email ? selectedUser.email : "",
          firstName: selectedUser.firstName ? selectedUser.firstName : "",
          lastName: selectedUser.lastName ? selectedUser.lastName : "",
          role: selectedUser.role ? selectedUser.role : "",
          password: "",
          confirmPass: "",
        }}
        validationSchema={EditUserSchema}
        onSubmit={(values, { setSubmitting }) => {
          mutationFunc({
            variables: {
              ...values,
            },
          });
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  Change User Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fieldName="email"
                  label="Email"
                  type="email"
                  disabled={true}
                  autocomplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fieldName="firstName"
                  label="First Name"
                  type="text"
                  autocomplete="first-name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fieldName="lastName"
                  label="Last Name"
                  type="text"
                  autocomplete="last-name"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelectField
                  options={ROLES}
                  fieldName="role"
                  label="Role"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  Change Password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomPasswordField
                  fieldName="password"
                  label="Password"
                  autocomplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomPasswordField
                  fieldName="confirmPass"
                  autocomplete="new-password"
                  label="Confirm Password"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditUserForm;
