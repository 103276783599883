import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    backgroundImage: "url(/images/wallpaper/main.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    background: "fixed",
  },
  content: {
    height: "100%",
  },
}));

const PublicLayout = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PublicLayout;
