import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";

const initialState = {
  currentUser: {},
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions for changing state
  function setCurrentUser(user) {
    dispatch({
      type: "SET_USER",
      payload: user,
    });
  }

  function removeCurrentUser() {
    dispatch({
      type: "REMOVE_USER",
      payload: {},
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        currentUser: state.currentUser,
        setCurrentUser,
        removeCurrentUser,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
