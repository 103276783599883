import React from "react";
import {
  Button,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { notifyErr } from "utils/helpers";
import { Formik, Form } from "formik";
import {
  CustomTextField,
  CustomPasswordField,
} from "components/CustomFormFields";
import { LoginSchema } from "utils/formSchema";
import PropTypes from "prop-types";
import { SETTINGS } from "utils/constants";
import AuthHelpers from "utils/AuthHelpers";

const useStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 50,
    paddingTop: 50,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  labIcon: {
    width: 100,
  },
}));

const SignInForm = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:960px)");

  const onSubmit = async (values, setSubmitting) => {
    try {
      await AuthHelpers.handleLogin({
        email: values.email.toLowerCase(),
        password: values.password,
      });
    } catch (err) {
      let errMessage = err.message.replace("GraphQL", "");
      notifyErr(errMessage);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={LoginSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form className={classes.form}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <img
                src={SETTINGS.logoDark}
                alt="lab icon svg"
                className={classes.labIcon}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" gutterBottom>
                Sales Portal
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                fieldName="email"
                label="Email"
                type="email"
                autocomplete="current-email"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomPasswordField
                fieldName="password"
                label="Password"
                autocomplete="current-password"
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                fullWidth
                size="medium"
                color="primary"
              >
                Sign In
              </Button>
            </Grid>
            <Grid item xs={12} md={12} align={matches ? "center" : "left"}>
              <Typography variant="body1">
                <Link href="/forgot-pass">Forgot Password?</Link>
              </Typography>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

SignInForm.propTypes = {
  history: PropTypes.object.isRequired,
};

export default SignInForm;
