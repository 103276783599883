// TOP LEVEL IMPORTS
import React from "react";
import { Route, Redirect } from "react-router-dom";

const AdminRoute = (props) => {
  const {
    currentUser,
    path,
    noNav,
    noFooter,
    theme,
    isAllowed,
    component: Component,
    layout: Layout,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      path={path}
      render={(args) => (
        <React.Fragment>
          {currentUser && currentUser.id ? (
            <Layout {...args} {...props}>
              <Component {...args} {...props} />
            </Layout>
          ) : (
            <Redirect to="/" />
          )}
        </React.Fragment>
      )}
    />
  );
};

export default AdminRoute;
