import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const handleDateChange = async (date, fetchData, currentUser, setLoading) => {
  let selectedMonth = moment(date).format("M");
  let selectedYear = moment(date).format("YYYY");
  setLoading(true);
  await fetchData(currentUser, selectedYear, selectedMonth);
};

const MonthPicker = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const { fetchData, currentUser, setLoading } = props;

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => {
        handleDateChange(date, fetchData, currentUser, setLoading);
        setStartDate(date);
      }}
      dateFormat="M/yyyy"
      maxDate={new Date()}
      showMonthYearPicker
    />
  );
};

export default MonthPicker;
