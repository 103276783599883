import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      user {
        id
        email
        role
        firstName
        lastName
        forcePasswordChange
      }
      token
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($email: String!) {
    deleteUser(email: $email) {
      id
    }
  }
`;

export const INVITE_USER = gql`
  mutation InviteUser($email: String!, $role: String!) {
    inviteUser(email: $email, role: $role) {
      sent
    }
  }
`;

export const CREATE_NEW_USER = gql`
  mutation CreateNewUser(
    $email: String!
    $password: String!
    $firstName: String
    $lastName: String
    $role: String!
  ) {
    registerUser(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        role: $role
      }
    ) {
      user {
        email
        role
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $email: String
    $password: String
    $firstName: String
    $lastName: String
    $role: String
  ) {
    updateUser(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        role: $role
      }
    ) {
      email
      firstName
      lastName
    }
  }
`;

export const SEND_RESET_PASS_LINK = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      sent
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $password: String!) {
    changePassword(input: { email: $email, password: $password }) {
      token
      user {
        id
        email
      }
    }
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation ChangeUserPassword($email: String!, $password: String!) {
    changeUserPassword(input: { email: $email, password: $password })
  }
`;
