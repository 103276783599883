import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { Button } from "@material-ui/core";

const handleDateChange = async (date, fetchData, currentUser) => {
  let selectedYear = moment(date).format("YYYY");
  await fetchData(currentUser, selectedYear);
};

const YearPicker = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const { fetchYearData, currentUser, yearData, enableCSV } = props;
  let selectedYear = moment(startDate).format("YYYY");

  const setButtonText = (en, sy) => {
    switch (en) {
      case 0:
        return "Select a Year to Export";
      case 1:
        return `Loading ${sy} data...`;
      case 2:
        return `Export ${sy} data`;
      default:
        return "Select a Year to Export";
    }
  };

  return (
    <div style={{ marginTop: 5, marginLeft: 10 }}>
      <Button
        variant="contained"
        color="primary"
        disabled={enableCSV !== 2}
        style={{ marginBottom: 10 }}
      >
        <CSVLink
          filename="sales-portal-data.csv"
          data={yearData}
          style={{ color: "white", fontWeight: 600 }}
        >
          {setButtonText(enableCSV, selectedYear)}
        </CSVLink>
      </Button>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          handleDateChange(date, fetchYearData, currentUser);
          setStartDate(date);
        }}
        maxDate={new Date()}
        showYearPicker
        dateFormat="yyyy"
      />
    </div>
  );
};

export default YearPicker;
