import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import SideDrawer from "./SideDrawer";
import PropTypes from "prop-types";
import { SETTINGS } from "utils/constants";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  link: {
    color: "white",
  },
  popoverContainer: {
    padding: 10,
  },
  labLogo: {
    width: 70,
  },
}));

const Topbar = (props) => {
  const [open, setOpen] = useState(true);
  const { handleLogout, currentUser } = props;
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
        style={{ backgroundColor: SETTINGS.primaryColor }}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <RouterLink to="/dashboard" className={classes.link}>
              <img
                src={SETTINGS.logoDark}
                alt="Lab Logo"
                className={classes.labLogo}
              />
            </RouterLink>
          </Typography>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={handleLogout}
          >
            <InputIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <SideDrawer setOpen={setOpen} open={open} user={currentUser} />
    </>
  );
};

Topbar.propTypes = {
  currentUser: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default Topbar;
