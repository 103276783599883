import { gql } from "@apollo/client";

export const ME = gql`
  query User {
    me {
      id
      firstName
      lastName
      email
      role
      forcePasswordChange
    }
  }
`;

export const USERS = gql`
  query Users {
    users {
      id
      firstName
      lastName
      email
      role
      forcePasswordChange
    }
  }
`;

export const VERIFY_USER_TOKEN = gql`
  query VerifyUserToken($token: String!) {
    verifyUserToken(token: $token) {
      role
      email
    }
  }
`;
