import MuiButton from "./MuiButton";
import MuiIconButton from "./MuiIconButton";
import MuiPaper from "./MuiPaper";
import MuiTableCell from "./MuiTableCell";
import MuiTableHead from "./MuiTableHead";
import MuiTypography from "./MuiTypography";
import MuiOutlinedInput from "./MuiOutlinedInput";
import MuiAutocomplete from "./MuiAutocomplete";

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiOutlinedInput,
  MuiAutocomplete,
  MuiTypography,
};
