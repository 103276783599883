import React, { useState, useMemo, useEffect, useCallback } from "react";
// import PropTypes from "prop-types";
import axios from "axios";
import { DataTable } from "components/DataTable/DataTable";
import { DataViewContainer } from "components/DataViewContainer/DataViewContainer";

const RepReview = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { currentUser, path } = props;

  const columns = useMemo(() => [
    {
      Header: "Account",
      accessor: "Account",
    },
    {
      Header: "Type",
      accessor: "Type",
    },
    {
      Header: "Sales Rep",
      accessor: "Rep",
    },
    {
      Header: "Supervising Rep",
      accessor: "Supervisor",
    },
  ]);

  const fetchData = useCallback(async (user) => {
    let url;
    let result;
    if (user.role === "Admin") {
      url = `${process.env.REACT_APP_REST_API_HOST}repreview?apikey=${process.env.REACT_APP_REST_API_KEY}`;
      result = await axios.get(url);
      setLoading(false);
      setData(result.data);
    } else {
      setData([]);
    }
  }, []);

  useEffect(() => {
    fetchData(currentUser);
  }, [fetchData, currentUser]);

  return (
    <DataViewContainer
      pageTitle="Rep Review Data"
      loading={loading}
      path={path}
    >
      {!loading ? (
        <>
          <DataTable data={data} columns={columns} path={path} />
        </>
      ) : undefined}
    </DataViewContainer>
  );
};

export default RepReview;
