import React from "react";
import { Formik, Form } from "formik";
import { Grid, Button, Typography } from "@material-ui/core";
import { notifyErr, notifySuccess } from "utils/helpers";
import {
  CustomTextField,
  CustomPasswordField,
} from "components/CustomFormFields";
import { EditUserSchema } from "utils/formSchema";
import { UPDATE_USER } from "apollo/mutations/userActions";
import { ME } from "apollo/queries/userQueries";
import { useMutation, useLazyQuery } from "@apollo/client";

const UserSettings = (props) => {
  const { currentUser } = props;

  const [grabUser] = useLazyQuery(ME);

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      grabUser();
      notifySuccess(`User ${data.updateUser.email} has been updated!`);
    },
    onError: (err) => {
      console.log(err);
      notifyErr("Something went wrong! Please try to refresh and try again!");
    },
  });

  return (
    <>
      <Typography variant="h2" style={{ marginBottom: 20 }} align="center">
        User Account Settings
      </Typography>
      <Formik
        initialValues={{
          email: currentUser.email,
          role: currentUser.role,
          firstName: currentUser.firstName ? currentUser.firstName : "",
          lastName: currentUser.lastName ? currentUser.lastName : "",
          password: "",
          confirmPass: "",
        }}
        validationSchema={EditUserSchema}
        onSubmit={(values, { setSubmitting }) => {
          updateUser({
            variables: {
              ...values,
            },
          });
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  Change User Details
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fieldName="email"
                  label="Email"
                  type="email"
                  disabled={true}
                  autocomplete="email"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fieldName="role"
                  label="Role"
                  type="text"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fieldName="firstName"
                  label="First Name"
                  type="text"
                  autocomplete="first-name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fieldName="lastName"
                  label="Last Name"
                  type="text"
                  autocomplete="last-name"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  Change Password
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomPasswordField
                  fieldName="password"
                  label="Password"
                  autocomplete="new-password"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomPasswordField
                  fieldName="confirmPass"
                  autocomplete="new-password"
                  label="Confirm Password"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserSettings;
