import React from "react";
import { Modal, Backdrop, Fade, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
  button: {
    marginRight: 20,
  },
  idleText: {
    padding: "10px 0",
  },
}));

const IdleModal = (props) => {
  const classes = useStyles();
  const { showModal, handleClose, handleLogout } = props;
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">Inactivity Timer</h2>
          <p className={classes.idleText}>
            You have been inactive for 10 minutes, would you like to stay logged
            in?
          </p>
          <Button
            variant="outlined"
            onClick={handleLogout}
            className={classes.button}
          >
            Logout
          </Button>
          <Button variant="outlined" onClick={props.handleClose}>
            Stay
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

IdleModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default IdleModal;
