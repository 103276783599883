import React, { useState, useMemo } from "react";
import { DataTable } from "components/DataTable/DataTable";
import { DataViewContainer } from "components/DataViewContainer/DataViewContainer";
import { USERS } from "apollo/queries/userQueries";
import {
  INVITE_USER,
  UPDATE_USER,
  DELETE_USER,
} from "apollo/mutations/userActions";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { CustomModal } from "components/CustomModal/CustomModal";
import { Button, Typography } from "@material-ui/core";
import InviteEmailModal from "./components/InviteEmailModal";
import { notifyErr, notifySuccess } from "utils/helpers";
import EditUserForm from "./components/EditUserForm";

const UserManagement = ({ path }) => {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [pwModal, setPwModal] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [selectedUser, setSelectedUser] = useState();

  const [getUsers] = useLazyQuery(USERS, {
    onCompleted: (data) => {
      setTableData(data.users);
    },
  });

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: (data) => {
      let newData = tableData.filter((row) => {
        return row.id !== data.deleteUser.id;
      });
      setTableData(newData);
      notifySuccess("User has been deleted");
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      handlePwClose();
      getUsers();
      notifySuccess(`User ${data.updateUser.email} has been updated!`);
    },
    onError: (err) => {
      console.log(err.message);
    },
  });

  const [inviteUser] = useMutation(INVITE_USER, {
    onCompleted: () => {
      notifySuccess(
        "An email with a registration link has been sent to their email"
      );
      handleUserModalClose();
    },
    onError: (error) => {
      notifyErr(error.message);
    },
  });

  const { loading, error } = useQuery(USERS, {
    onCompleted: (data) => {
      setTableData(data.users);
    },
  });

  const handleUserModalClose = () => setUserModal(false);
  const handleClose = () => setOpen(false);
  const handlePwClose = () => setPwModal(false);
  const handleUserInviteModalOpen = () => setUserModal(true);

  const handleModal = (email) => {
    setOpen(true);
    setUserEmail(email);
  };

  const handlePwOpen = (values) => {
    setPwModal(true);
    setSelectedUser(values);
  };

  const handleDeleteUser = () => {
    deleteUser({
      variables: {
        email: userEmail,
      },
    });
    let filteredData = tableData.filter((user) => user.email !== userEmail);
    setTableData(filteredData);
    setUserEmail("");
    setOpen(false);
  };

  const columns = useMemo(() => [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Delete Action",
      accessor: "deleteUser",
      Cell: ({ cell }) => (
        <Button
          id={cell.row.values.id}
          variant="contained"
          color="primary"
          style={{ fontWeight: 600, fontSize: 12 }}
          onClick={() => {
            handleModal(cell.row.values.email);
          }}
        >
          Delete User
        </Button>
      ),
    },
    {
      Header: "Edit User",
      accessor: "passwordChange",
      Cell: ({ cell }) => (
        <Button
          id={cell.row.values.id}
          variant="contained"
          color="primary"
          style={{ fontWeight: 600, fontSize: 12 }}
          onClick={() => {
            handlePwOpen(cell.row.values);
          }}
        >
          Edit User
        </Button>
      ),
    },
  ]);

  if (loading) {
    return "Loading...";
  }

  if (error) {
    console.log(error.message);
  }

  return (
    <DataViewContainer
      pageTitle="User Management"
      button="Invite New User"
      clickFunction={handleUserInviteModalOpen}
      path={path}
    >
      <DataTable data={tableData} columns={columns} path={path} />
      {/* Confirmation modal for delete user */}
      <CustomModal open={open} handleClose={handleClose}>
        <Typography gutterBottom style={{ marginBottom: 20 }}>
          Are you sure you want to delete this user?
        </Typography>
        <Button variant="contained" color="primary" onClick={handleDeleteUser}>
          Confirm
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          style={{ marginLeft: 10 }}
        >
          Cancel
        </Button>
      </CustomModal>
      {/* Modal to change user password */}
      <CustomModal
        open={pwModal}
        handleClose={handlePwClose}
        className="edit-user-modal"
      >
        <EditUserForm selectedUser={selectedUser} mutationFunc={updateUser} />
      </CustomModal>
      {/* Modal to invite new user */}
      <CustomModal open={userModal} handleClose={handleUserModalClose}>
        <InviteEmailModal
          inviteUser={inviteUser}
          handleClose={handleUserModalClose}
        />
      </CustomModal>
    </DataViewContainer>
  );
};

export default UserManagement;
